<template>
  <v-dialog
    v-model="isShowModal"
    max-width="800px"
    persistent>
    <v-card>
      <material-card
        :title="$t('evoucher.editReferenceInfo')"
        tile
        color="green"
        full-width
      >
        <v-container
          grid-list-xl
          fluid
          style="padding: 0;">
          <v-form
            ref="form"
            lazy-validation>
            <v-layout
              layout
              wrap>
              <v-flex
                xs12
                sm4
                md4>
                <v-text-field
                  v-model="orderCode"
                  :rules="[ruleRequiredValue]"
                  :label="$t('evoucher.referenceCode')"
                  :name="$t('evoucher.referenceCode')"
                  class="required"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-menu
                  ref="menu"
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="startDateFormatted"
                      :label="$t('evoucher.arrivalDate')"
                      persistent-hint
                      @blur="date = parseDate(startDateFormatted)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    :min="minStartDate"
                    no-title
                    @input="menuStartDate = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-menu
                  ref="menu"
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endDateFormatted"
                      :label="$t('evoucher.departureDate')"
                      persistent-hint
                      @blur="date = parseDate(endDateFormatted)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    :min="minEndDate"
                    no-title
                    @input="menuEndDate = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-text-field
                  v-model="customerName"
                  :label="$t('evoucher.customerUsed')"
                  :name="$t('evoucher.customerUsed')"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-text-field
                  v-model="customerEmail"
                  :label="$t('customer.emailCustomer')"
                  :name="$t('customer.emailCustomer')"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-text-field
                  v-model="customerPhone"
                  :label="$t('customer.phone')"
                  :name="$t('customer.phone')"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm12
                md12>
                <v-textarea
                  v-model="note"
                  :label="$t('common.note')"
                  :name="$t('common.note')"
                  type="text"
                  rows="1"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="isShowModal = false"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm($event)"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex'
import ToastType from 'enum/toastType'
import dateUtils from 'utils/dateUtils'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import moment from 'moment'
export default {
  data () {
    return {
      isShowModal: false,
      isLoading: false,
      referenceId: null,
      orderCode: '',
      customerName: '',
      customerPhone: '',
      customerEmail: '',
      note: null,

      startDate: null,
      startDateFormatted: null,
      menuStartDate: false,
      minStartDate: null,
      endDate: null,
      endDateFormatted: null,
      menuEndDate: false,
      minEndDate: null
    }
  },
  watch: {
    startDate (val) {
      this.startDateFormatted = this.formatDate(this.startDate)
      if (this.startDate !== null) {
        if (this.startDate >= this.endDate) {
          this.endDate = moment(this.startDate, 'YYYY-MM-DD')
            .add(1, 'days')
            .format('YYYY-MM-DD')
        }
        this.minEndDate = moment(this.startDate, 'YYYY-MM-DD')
          .add(1, 'days')
          .format('YYYY-MM-DD')
      } else {
        this.minEndDate = null
      }
    },
    endDate (val) {
      this.endDateFormatted = this.formatDate(this.endDate)
    }
  },
  methods: {
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Confirm
     */
    onConfirm: function (event) {
      event.preventDefault()
      if (this.$refs.form.validate()) {
        this.isLoading = true
        let filter = {
          refId: this.referenceId,
          orderCode: this.orderCode,
          customerName: this.customerName,
          customerPhone: this.customerPhone,
          customerEmail: this.customerEmail,
          note: this.note,
          startDate: !functionUtils.isEmptyString(this.startDate)
            ? dateUtils.formatDate(this.startDate, dateUtils.STATIC_FORMAT_DATE_SQL, 'YYYY-MM-DD HH:mm:ss') : null,
          endDate: !functionUtils.isEmptyString(this.endDate)
            ? dateUtils.formatDate('23:59:59 ' + this.endDate, 'HH:mm:ss YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss') : null
        }
        this.UPDATE_VOUCHER_USAGE_REFERENCE_INFO(filter).then(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.isLoading = false
            this.isShowModal = false
            this.$emit('onConfirm')
          }.bind(this)
        ).catch(
          function (error) {
            this.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(`${errorText}`),
                styletype: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
      }
    },
    /**
     * Show modal
     */
    onShowModal: function (refItem) {
      this.referenceId = refItem.id
      this.orderCode = refItem.orderCode
      this.customerName = refItem.customerName
      this.customerPhone = refItem.customerPhone
      this.customerEmail = refItem.customerEmail
      this.startDate = dateUtils.formatComponentDate(refItem.startDate, null)
      this.endDate = dateUtils.formatComponentDate(refItem.endDate, null)
      this.note = refItem.note
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'UPDATE_VOUCHER_USAGE_REFERENCE_INFO'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
